/* components/SearchPage.module.css */
.container {
  padding: 16px 6px;
}

.listContainer {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.loader {
  text-align: center;
  margin: 20px 0;
}

.loadMore {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #d12d34;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.loadMore:hover {
  background-color: #c02b2f;
}

.error {
  color: red;
  text-align: center;
}
