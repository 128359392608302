@import '../mixins';

.mainContainer {
  position: fixed;
  bottom: 10px;
  width: 100%;
  padding: 0 16px;
  z-index: 9999999;
  &_navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    position: relative;
    background: transparent;
    padding: 0 20px;
    &_overlay {
      z-index: -1;
      width: 100%;
      position: absolute;
      height: 100%;
      left: 0;
      svg {
        width: 100%;
      }
    }
    &_button {
      background: transparent;
      border: none;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      span {
        display: none;
      }
    }
    &_pbutton {
      background: transparent;
      border: none;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      background-color: #d12d34;
      margin-top: -75px;
      span {
        display: none;
      }
    }
  }
}
