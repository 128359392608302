@import '../mixins';
.TopLocations {
  width: 100%;
  display: flex;
  padding: 10px 60px;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  @include mobile {
    padding: 0 16px;
    margin: 0;
  }
  &_heading {
    color: #000;
    margin: 10px 0;
    font-size: 1.2rem;
    text-align: center;
    font-weight: 500;
  }
  &_headingdiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    &_headingspan {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: var(--main-text);
      display: flex;
      flex-direction: column;
      margin: 0;
      gap: 5px;
      span {
        color: var(--main-text);
        font-size: 14px;
      }
      @include mobile {
        font-size: 18px;
      }
    }
    a {
      margin-left: auto;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height */

      span {
        text-decoration-line: underline;
        color: $--sec-text;
        @include mobile {
          font-size: 14px;
          line-height: 17px;
        }
        &:first-of-type {
          color: $--primary;
        }
      }
    }
  }
  &_muitabs {
    min-height: 30px !important;
    height: 42px !important;

    margin-left: auto;
    font-family: Montserrat !important;
    @include mobile {
      border: none;
      border-radius: 0;
      width: 100%;
    }
    button {
      transition: all 0.3s ease;
      font-family: Montserrat !important;
      margin-right: 10px;
      max-width: 100%;
    }
  }
  &_tab {
    width: max-content;

    font-family: 'Nunito' !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
    min-height: 35px !important;
    font-weight: 600 !important;

    font-stretch: normal;

    font-style: normal;

    line-height: 1.19 !important;

    letter-spacing: normal;

    text-align: left;
    color: #000 !important;
    border: 1px solid #f5f4f8 !important;
    border-radius: 20px !important;
    height: 38px !important;
    background-color: #f5f4f8 !important;
    &:last-of-type {
      margin-right: 0;
    }
  }
  &_selectedtab {
    width: max-content;
    font-family: 'Nunito' !important;
    text-transform: capitalize !important;
    min-height: 35px !important;
    font-size: 16px !important;
    font-weight: 600;

    font-stretch: normal;
    height: 38px !important;
    font-style: normal;

    line-height: 1.19;

    letter-spacing: normal;

    text-align: left;
    color: #fff !important;
    border: 1px solid #d12d34 !important;
    border-radius: 20px !important;
    background-color: #d12d34 !important;
  }
  &_itemss {
    padding: 10px 0;
  }
  &_item {
    color: white;
    min-height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #b20e15;
  }
}
