@import '../mixins';
.mainContainer {
  display: flex;
  flex-direction: column;

  position: relative;

  &_head {
    height: 736px;
    position: relative;
    width: 100%;
    top: -100px;

    @include mobile {
      height: 350px;
    }
    &_oimg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    &_content {
      z-index: 2;
      display: flex;
      gap: 162px;
      padding: 0;
      justify-content: center;
      height: 100%;
      width: 100%;
      @include mobile {
        gap: 20px;
        padding-left: 16px;
        padding-right: 16px;
        justify-content: space-between;
        align-items: flex-end;
      }
      &_left {
        z-index: 2;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include mobile {
          width: 180px;
          height: max-content;
          margin-bottom: 50px;
        }
        h1 {
          color: #fff;
          font-size: 46px;
          font-weight: 700;
          margin-bottom: 20px;
          width: 550px;
          @include mobile {
            font-size: 16px;
            width: 100%;
          }
        }
        h2 {
          color: #fff;
          font-size: 26;
          font-weight: 700;
          margin-bottom: 20px;
          width: 550px;
          @include mobile {
            font-size: 16px;
            width: 100%;
          }
        }
        &_buttons {
          display: flex;
          flex-direction: column;
          gap: 10px;
          @include mobile {
            width: 100%;
          }
          &_btn {
            display: flex;
            align-items: center;
            gap: 15px;
            @include mobile {
              gap: 5px;
              width: 100%;
            }
            button {
              background: none;
              border: none;
              outline: none;
              @include mobile {
                width: 48%;
              }
            }
            img {
              width: 214px;
              height: 64px;
              @include mobile {
                height: 30px;
                width: 100%;
              }
            }
          }
          &_search {
            display: flex;
            // border: 1px solid #d9d9d9;
            border-radius: 35px;
            height: 44px;
            overflow: hidden;
            @include mobile {
              height: 30px;
              width: 100%;
            }
            input {
              width: 60%;
              border: none;
              background-color: none;
              padding-top: 10px;
              padding-bottom: 10px;
              padding-left: 24px;
              color: #050505;
              font-size: 16px;
              padding-right: 4px;
              @include mobile {
                font-size: 14px;
                padding-left: 8px;
              }
            }
            button {
              background: none;
              border: none;
              outline: none;
              font-size: 16px;
              font-weight: 500;
              padding: 0 10px;
              text-align: center;
              background-color: #d12d34;
              color: #fff;
              width: 40%;
              @include mobile {
                font-size: 10px;
              }
            }
          }
        }
      }
      &_right {
        position: relative;
        height: 100%;
        width: 416px;
        z-index: 2;
        @include mobile {
          width: 100px;
        }
        img {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 416px;
          height: 602px;
          z-index: 2;
          @include mobile {
            width: auto;
            height: 200px;
          }
        }
      }
    }
  }
  &_network {
    width: 100%;
    height: 733px;

    background: radial-gradient(
      53.75% 207.44% at 46.25% 52.86%,
      rgba($color: #ffe4e4, $alpha: 1) 38.73%,
      rgba($color: #ffe4e4, $alpha: 1) 100%
    );

    @include center;
    padding: 30px 50px;
    position: relative;
    @include mobile {
      height: 200px;
      padding: 30px 16px;
    }
    img {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      @include mobile {
        width: calc(100% - 32px);
        height: auto;
        top: 20px;
        left: 16px;
      }
    }
    h2 {
      font-size: 32px;
      color: #050505;
      font-weight: 500;
      width: 650px;
      z-index: 2;
      text-align: center;
      @include mobile {
        font-size: 12px;
        width: 100%;
      }
      span {
        color: #d12d34;
        font-weight: 700;
      }
    }
  }
  &_world {
    background: #fff;

    position: relative;
    width: 100%;
    @include mobile {
      height: max-content;
    }
    &_bimg {
      position: absolute;
      left: 0;
      top: 50px;
      left: 15px;
      z-index: 0;
      @include mobile {
        width: auto;
        height: 380px;
      }
    }
    &_content {
      display: flex;
      z-index: 2;

      width: 100%;
      justify-content: flex-start;
      align-items: center;
      padding: 60px 0;
      padding-left: 220px;
      padding-bottom: 80px;
      gap: 163px;
      @include mobile {
        gap: 10px;
        padding: 20px;
        justify-content: space-between;
        flex-direction: column-reverse;
      }
      img {
        z-index: 0;
        height: 474px;
        width: auto;
        @include mobile {
          height: 260px;
          width: auto;
          transform: rotate(15deg);
        }
      }
      &_texts {
        width: 474px;
        flex-direction: column;
        display: flex;
        gap: 10px;
        z-index: 4;
        position: relative;
        @include mobile {
          width: 100%;
          flex-grow: 1;
        }
        h2 {
          font-size: 32px;
          color: #050505;
          font-weight: 700;
          @include mobile {
            font-size: 17px;
          }
          span {
            font-size: 32px !important;
            color: #d12d34;
            font-weight: 700;
            @include mobile {
              font-size: 16px !important;
            }
          }
        }
        span {
          font-size: 16px;
          color: #050505;
          font-weight: 400;
          @include mobile {
            font-size: 12px;
          }
        }
        a {
          border-bottom: 2px solid #d12d34;
          width: max-content;
          font-weight: 14px;
          @include mobile {
            font-size: 12px;
          }
        }
      }
    }
  }
  &_curve {
    background-color: #2a2a2e;
    width: 100%;
    height: 616px;
    position: relative;
    padding-top: 24px;
    padding-bottom: 107px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    @include mobile {
      height: max-content;
      padding: 16px;
      flex-direction: column;
    }
    &_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include mobile {
        display: none;
      }
    }
    &_texts {
      width: 520px;
      flex-direction: column;
      display: flex;
      gap: 10px;
      z-index: 4;
      position: relative;
      @include mobile {
        width: 100%;
      }
      h2 {
        font-size: 32px;
        color: #050505;
        font-weight: 700;
        @include mobile {
          font-size: 16px;
          color: #f2f2f2;
        }
        span {
          font-size: 32px !important;
          color: #d12d34;
          font-weight: 700;
          @include mobile {
            font-size: 16px !important;
          }
        }
      }
      span {
        font-size: 16px;
        color: #050505;
        font-weight: 400;
        height: 98px;
        text-overflow: ellipsis;
        overflow: hidden;
        @include mobile {
          font-size: 12px;
          color: #f2f2f2;
        }
      }
      a {
        border-bottom: 2px solid #d12d34;
        width: max-content;
        font-weight: 14px;
        color: #050505;
        @include mobile {
          color: #f2f2f2;
          font-size: 12px;
        }
      }
    }
    &_mobile {
      width: max-content;
      z-index: 2;
      img {
        width: 550px;
        height: auto;
        @include mobile {
          width: 240px;
          height: auto;
        }
      }
    }
  }
  &_tailored {
    background-color: #2a2a2e;
    width: 100%;
    height: 616px;
    padding: 50px 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
      height: max-content;
      padding: 16px;
      flex-direction: column;
    }
    &_texts {
      width: 520px;
      flex-direction: column;
      display: flex;
      gap: 10px;
      z-index: 4;
      position: relative;
      @include mobile {
        width: 100%;
      }
      h2 {
        font-size: 32px;
        color: #ffffff;
        font-weight: 700;
        @include mobile {
          font-size: 16px;
          color: #f2f2f2;
        }
        span {
          font-size: 32px !important;
          color: #d12d34;
          font-weight: 700;
          @include mobile {
            font-size: 16px !important;
          }
        }
      }
      span {
        font-size: 16px;
        color: #ffffff;
        font-weight: 400;
        height: 98px;
        text-overflow: ellipsis;
        overflow: hidden;
        @include mobile {
          font-size: 12px;
          color: #f2f2f2;
        }
      }
      a {
        border-bottom: 2px solid #d12d34;
        width: max-content;
        font-weight: 14px;
        color: #ffffff;
        @include mobile {
          color: #f2f2f2;
          font-size: 12px;
        }
      }
    }
    &_mobile {
      width: max-content;
      z-index: 2;
      img {
        height: 500px;
        width: auto;
        @include mobile {
          height: 240px;
        }
      }
    }
  }
  &_realtime {
    background-color: #2a2a2e;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 0;
    display: flex;
    align-items: center;

    justify-content: center;
    @include mobile {
      height: max-content;
      padding: 16px;
      flex-direction: column;
    }
    &_texts {
      width: 520px;
      flex-direction: column;
      display: flex;
      gap: 10px;
      z-index: 4;
      position: relative;
      @include mobile {
        width: 100%;
      }
      h2 {
        font-size: 32px;
        color: #ffffff;
        font-weight: 700;
        @include mobile {
          font-size: 16px;
          color: #f2f2f2;
        }
        span {
          font-size: 32px !important;
          color: #d12d34;
          font-weight: 700;
          @include mobile {
            font-size: 16px !important;
          }
        }
      }
      span {
        font-size: 16px;
        color: #ffffff;
        font-weight: 400;
        height: 98px;
        text-overflow: ellipsis;
        overflow: hidden;
        @include mobile {
          font-size: 12px;
          color: #f2f2f2;
        }
      }
      a {
        border-bottom: 2px solid #d12d34;
        width: max-content;
        font-weight: 14px;
        color: #ffffff;
        @include mobile {
          color: #f2f2f2;
          font-size: 12px;
        }
      }
    }
    &_mobile {
      width: max-content;
      z-index: 2;
      img {
        height: 500px;
        width: auto;
        @include mobile {
          height: 240px;
          left: -20px;
          position: relative;
        }
      }
    }
  }
  &_verified {
    background-color: #f2f2f2;
    width: 100%;

    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
      height: max-content;
      padding: 16px;
      flex-direction: column-reverse;
    }
    &_texts {
      width: 520px;
      flex-direction: column;
      display: flex;
      gap: 10px;
      z-index: 4;
      position: relative;
      @include mobile {
        width: 100%;
      }
      h2 {
        font-size: 32px;
        color: #050505;
        font-weight: 700;
        @include mobile {
          font-size: 16px;
          color: #050505;
        }
        span {
          font-size: 32px !important;
          color: #d12d34;
          font-weight: 700;
          @include mobile {
            font-size: 16px !important;
          }
        }
      }
      span {
        font-size: 16px;
        color: #000;
        font-weight: 400;
        height: 98px;
        text-overflow: ellipsis;
        overflow: hidden;
        @include mobile {
          font-size: 12px;
          color: #050505;
        }
      }
      a {
        border-bottom: 2px solid #d12d34;
        width: max-content;
        font-weight: 14px;
        color: #000;
        @include mobile {
          color: #050505;
          font-size: 12px;
        }
      }
    }
    &_mobile {
      width: max-content;
      z-index: 2;
      img {
        height: 500px;
        width: auto;
        @include mobile {
          height: 240px;
        }
      }
    }
  }
  &_requirements {
    flex-direction: column;
    padding: 80px 20px;
    display: flex;
    width: 100%;
    background: #ffffff;
    align-items: center;
    justify-content: center;

    @include mobile {
      padding: 20px;
    }
    h2 {
      font-size: 32px;
      color: #050505;
      font-weight: 700;
      @include mobile {
        font-size: 16px;
      }
      span {
        font-size: 32px !important;
        color: #d12d34;
        font-weight: 700;
        @include mobile {
          font-size: 16px !important;
        }
      }
    }
  }
  &_newsletter {
    background-color: #ffe4e4;
    background: linear-gradient(
        0deg,
        rgba($color: #ffe4e4, $alpha: 0.8),
        rgba($color: #ffe4e4, $alpha: 0.8)
      ),
      linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.16) 0%,
        rgba(255, 255, 255, 0.16) 50%,
        rgba(202, 202, 202, 0.8) 100%
      );

    width: 100%;
    padding-top: 40px;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
      height: max-content;
      padding: 16px;
      flex-direction: column;
    }
    &_texts {
      width: 520px;
      flex-direction: column;
      display: flex;
      gap: 10px;
      z-index: 4;
      position: relative;
      @include mobile {
        width: 100%;
      }
      h2 {
        font-size: 32px;
        color: #050505;
        font-weight: 700;
        @include mobile {
          font-size: 16px;
          color: #050505;
        }
        span {
          font-size: 32px !important;
          color: #d12d34;
          font-weight: 700;
          @include mobile {
            font-size: 16px !important;
          }
        }
      }
      span {
        font-size: 16px;
        color: #050505;
        font-weight: 400;
        // height: 98px;
        text-overflow: ellipsis;
        overflow: hidden;
        @include mobile {
          font-size: 12px;
          color: #050505;
        }
      }
      a {
        border-bottom: 2px solid #d12d34;
        width: max-content;
        font-weight: 14px;
        color: #050505;
        @include mobile {
          color: #050505;
          font-size: 12px;
        }
      }
    }
    &_mobile {
      width: max-content;
      z-index: 2;
      @include mobile {
        margin-top: 20px;
      }
      img {
        height: 400px;
        width: auto;
        @include mobile {
          height: 220px;
        }
      }
    }
  }
}
